import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import env from "react-dotenv";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { select } from '@storybook/addon-knobs';

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator
} from "@chatscope/chat-ui-kit-react";
import './App.css';
var n = 1; 
var surgeryName = '';
const questions = [
  "What is the purpose and necessity of the surgery?",
  "What is the procedure like and how is it performed?",
  "What are the potential risks and complications?",
  "What is the expected recovery process?",
  "What are the potential long-term outcomes and follow-up care?",
];
const questionListing = [
  "What should I expect in the recovery process?",
  "When can I start eating and drinking normally?",
  "What kind of pain can I expect and how can it be managed?",
  "Are there any physical activities that I should avoid?",
  "What are the signs of complications I should watch for?",
  "When can I resume my regular medications?",
  "What are the wound care instructions?",
  "When will the sutures/stitches be removed?",
  "When can I go back to work?",
  "What kind of help will I need at home?",
];
const questionListingIndex = [
  "What should I expect in the recovery process?",
  "When can I start eating and drinking normally?",
  "What kind of pain can I expect and how can it be managed?",
  "Are there any physical activities that I should avoid?",
  "What are the signs of complications I should watch for?",
  "When can I resume my regular medications?",
  "What are the wound care instructions?",
  "When will the sutures/stitches be removed?",
  "When can I go back to work?",
  "What kind of help will I need at home?",
];
let questionListingResponse = "";
const App = () => {
  const API_KEY = env.REACT_APP_OPENAI_API_KEY;

  useEffect(() => { 
    // Check if the IP address is already stored in localStorage
    const storedIpAddress = localStorage.getItem('userIpAddress');
    const storedN = localStorage.getItem('storedN');
    //alert(storedN);
    // if (storedN !== null ) {
    //   n = storedN;
    //   //alert("Enter:"+storedN);
    //   if(n >= 4){
    //     setShowMessageInputDiv(false);
    //   }
    // }
    if (storedIpAddress) {
      console.log('IP address already in localStorage:', storedIpAddress);
    } else {
      // Fetch the IP address using ipify API
      axios.get('https://api.ipify.org?format=json')
        .then(response => {
          const ipAddress = response.data.ip;

          // Store the IP address in localStorage
          localStorage.setItem('userIpAddress', ipAddress);

          console.log('IP address stored in localStorage:', ipAddress);
        })
        .catch(error => {
          console.error('Failed to fetch IP address:', error);
        });
    }
  }, []);

  const [showExtraQuestionDiv, setShowExtraQuestionDiv] = useState(false);
  const [showMessageInputDiv, setShowMessageInputDiv] = useState(true);
  const [showLimitMessage, setShowLimitMessage] = useState(false);

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm PECA! let me know about the Surgery name.",
      sentTime: "just now",
      sender: "PECA",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
   
  const handleSendRequest = async (message) => {
    setShowMessageInputDiv(false);
    setShowLimitMessage(false);
    var newMessage = {
      message,
      direction: 'outgoing',
      sender: "user",
      copy : ''
    };
    console.log(newMessage);
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);
    if(n == 1){
      try { 
          surgeryName = extractPlainText(message);
          var responses= [];
          let firstRespForExtraQuestionRequest;
          for (let i = 1; i <= 4; i++) {
              var question_request = await questions[i];
              var prompt = 'Surgery Name: '+surgeryName+' For a given surgery name, provide short  answers , If the surgery name is not valid, respond  "Surgery name is not valid". The structured information should include asnwer to this question:  '+question_request+' Please note that you should only provide information in html structured format, and if the surgery name is invalid, use the specified format for the response. DO NOT GIVE ANYOTHER TEXT.';
              console.log(prompt);
              newMessage.copy = await prompt;
              const responsePromise = processMessageToChatGPT([...messages, newMessage]);
              if(!firstRespForExtraQuestionRequest){
                firstRespForExtraQuestionRequest = responsePromise;
              }
              responses.push(responsePromise);
          }
          waitForAsyncOperationWithTimeout(() => firstRespForExtraQuestionRequest, 10000)
          var question1 = await questions[0];
          var prompt1 = 'Surgery Name: '+surgeryName+'  For a given surgery name, provide short answers , If the surgery name is not valid, respond  "Surgery name is not valid". The structured information should include asnwer to this question:  '+question1+' Please note that you should only provide information in html structured format, and if the surgery name is invalid, use the specified format for the response. DO NOT GIVE ANYOTHER TEXT.';
          console.log("prompt1:"+prompt1);
          newMessage.copy = await prompt1;
          var firstResponse =  await processMessageToChatGPT([...messages, newMessage]);


          const content1 = firstResponse.choices[0]?.message?.content;
         // alert(countWords(content1));
          if(countWords(content1) > 50){
            const youtubeResponse = {
              message: 'Click on the Link for latest videos <a target="_blank" href="https://www.youtube.com/results?search_query='+surgeryName+'">Link</a>',
              sender: "PECA",
            };
            setMessages((prevMessages) => [...prevMessages, youtubeResponse]);
            
            // You can do further processing here
            var html ="<b>"+question1+"</b> <br>"+content1;
            const chatGPTResponse = {
                message: html,
                sender: "PECA",
            };
            setMessages((prevMessages) => [...prevMessages, chatGPTResponse]); 
            //await sleep(5000); 
            handleClick("auto");
          }
          const responseArray = await Promise.all(responses);
          for (let i = 0; i < responseArray.length; i++) {
            var question = questions[i+1];
            const content = responseArray[i].choices[0]?.message?.content;
            //alert(countWords(content));
            console.log(`Response ${i + 1}:`, content);
            console.log(countWords(content));
            if( countWords(content) < 50 ){
              const chatGPTResponse = {
                message: "The name you have entered is not valid, please enter a valid surgery name so we can provide you the details.",
                sender: "PECA",
              };
              setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
              n--;
              i = 7;
            }else{
              // You can do further processing here
              var html ="<b>"+question+"</b> <br>"+content;
              const chatGPTResponse = {
                  message: html,
                  sender: "PECA",
              };
              setMessages((prevMessages) => [...prevMessages, chatGPTResponse]); 
              //await sleep(5000); 
              handleClick("auto");
            }
          } 
      } catch (error) {
        console.error("Error processing message:", error);
      } finally {
        setIsTyping(false);
      }
    }else{
        try {
          const response = await processMessageToChatGPT([...messages, newMessage]);
          var content = response.choices[0]?.message?.content;
          console.log(content);
          const chatGPTResponse = {
              message: content,
              sender: "PECA",
          };
          setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
          handleClick("auto");
        } catch (error) {
            console.error("Error processing message:", error);
        } finally {
            setIsTyping(false);
        }
    }
    setShowMessageInputDiv(true);
    console.log("DOne");
    n++;
    localStorage.setItem('storedN', n);
    // if(n == 4){
    //   setShowMessageInputDiv(false);
    // }
  };
  const handlePrint = () => {
    window.print();
  };
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  function countWords(paragraph) {
    // Remove leading and trailing whitespaces
    const trimmedParagraph = paragraph.trim();
  
    // Split the paragraph into an array of words
    const words = trimmedParagraph.split(/\s+/);
  
    // Filter out empty strings (resulting from multiple whitespaces)
    const filteredWords = words.filter(word => word !== '');
  
    // Return the count of words
    return filteredWords.length;
  }
  
  async function generateExtraQuestions(){

    var responsesListing= [];
    for (let i = 0; i <= questionListing.length; i++) {
        var question = questionListing[i];
        //question name at top,
        var prompt = 'Surgery Name: '+surgeryName+'  For a given surgery name, provide  short answers , If the surgery name is not valid, respond  "Surgery name is not valid". The structured information should include asnwer to this question:  '+question+' Please note that you should only provide information in html structured format, and if the surgery name is invalid, use the specified format for the response. DO NOT GIVE ANYOTHER TEXT.';
        console.log(prompt);
        //await sleep(2000); 
        var newMessage = {
          message : prompt,
          direction: 'outgoing',
          sender: "user",
        };
        
        const responsePromiseresponsesListing = processMessageToChatGPT([...messages, newMessage]);
        responsesListing.push(responsePromiseresponsesListing);
    }

    questionListingResponse = await Promise.all(responsesListing);
    console.log(questionListingResponse);
    //alert(!showExtraQuestionDiv);
    setShowExtraQuestionDiv(true);
  }
  function waitForAsyncOperationWithTimeout(asyncOperation, timeout) {
    const startTime = performance.now();
  
    const responsePromise = asyncOperation();
  
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Timeout reached'));
      }, timeout);
    });
  
    return Promise.race([responsePromise, timeoutPromise])
      .then((response) => {
        const endTime = performance.now();
        const elapsedTime = endTime - startTime;
        console.log('Elapsed time:', elapsedTime, 'milliseconds');
        return response;
      })
      .catch((error) => {
        console.error('291 Error:', error);
        generateExtraQuestions();
      });
  }
  const handleItemClick = (item) => {
    //alert(item);
    var i = questionListingIndex.indexOf(item);
    //alert(i);
    var question = item;
   // alert(question);
    var answerResponse = questionListingResponse[i].choices[0]?.message?.content;
   // alert(answerResponse);
    if (typeof answerResponse !== "string") {
      answerResponse = JSON.stringify(questionListingResponse[i+1]);
    }
    //var answer = answerResponse.replace(/[\[\]{}"]/g, '');
    var answer = answerResponse;
    var html ="<b>"+question+"</b> <br>"+answer;
    const chatGPTResponse = {
    message: html,
    sender: "PECA",
    };
    var iiii = questionListing.indexOf(item);
    questionListing.splice(iiii, 1);
    setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
    handleClick("auto");
  }
 
  const listItemElements = questionListing.map((item, index) => ( 
    <li className="list-group-item" key={index} onClick={() => handleItemClick(item)} >{item}</li>
  ));
  function extractPlainText(htmlText) {
    // Create a DOMParser
    var parser = new DOMParser();

    // Parse the HTML string
    var doc = parser.parseFromString(htmlText, 'text/html');

    // Extract the text content
    return doc.body.textContent || "";
  }
  async function processMessageToChatGPT(chatMessages) { 
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "PECA" ? "assistant" : "user";
      if(messageObject.copy && messageObject.copy != ""){
        var content = (messageObject.copy) ? messageObject.copy : '';
      }else{
        var content = (messageObject.message) ? messageObject.message : '';
      }
     
      return { role, content: content };
    });
    //console.log(...apiMessages);
    const apiRequestBody = {
      "model": "gpt-4",
      "temperature" : 0,
      "seed" : 1,
      "messages": [
        { role: "system", content: "surgery patient" },
        ...apiMessages,
      ],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    if (response.ok) {
      // If the response is okay, return the JSON data
      return response.json();
    } else {
      if (response.status === 429) {
        // Rate limit exceeded, handle accordingly
        console.log('Rate limit exceeded. Retrying...'+showLimitMessage); 
        await sleep(20000);
        // if(showLimitMessage == false){
        //   await setShowLimitMessage(true);
        //   // const chatGPTResponse = {
        //   //   message: "Rate limit exceeded. Please wait..",
        //   //   sender: "PECA",
        //   // };
        //   // setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
        // }
      } else {
        // Handle other errors
        const errorMessage = await response.text();
        console.error(`Request failed with status ${response.status}: ${errorMessage}`);
        setShowMessageInputDiv(false);
      }
    }
  }
  const [divHeight, setDivHeight] = useState(0);
  useEffect(() => {
    // Calculate the window height
    const windowHeight = window.innerHeight;

    // Calculate the desired height (20% less than the window height)
    const desiredHeight = windowHeight * 0.7;

    // Set the calculated height to the div
    setDivHeight(desiredHeight);

    // Add an event listener to update the height on window resize
    const handleResize = () => {
      const newHeight = window.innerHeight * 0.7;
      setDivHeight(newHeight);
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);





  const label = 'Scroll Behavior';
  const options = {
    Auto: "auto",
    Smooth: "smooth",
    None: undefined
  };
  const scrollBehavior = select(label, options, undefined);
  /* END Storybook knobs */

  const msgListRef = useRef();

  const handleClick = scrollBehavior => msgListRef.current.scrollToBottom(scrollBehavior);





  return (
    <div className="container-fluid">
      {/* <button direction="down" labelPosition="left" border onClick={() => handleClick("auto")}>Scroll to bottom (force auto)</button>
                <button direction="down" labelPosition="left" border onClick={() => handleClick("smooth")}>Scroll to bottom (force smooth)</button>
                <button direction="down" labelPosition="left" border onClick={() => handleClick()}>Scroll to bottom (behavior from props)</button> */}

      <div className="row">
        <div className="col-md-8">
        <button onClick={handlePrint} className="btn btn-success mb-2 print_button">Print Chat</button>
        <div className="App"  style={{ height: `${divHeight}px`, overflowY: "scroll", overflow: "hidden" }}>
            <MainContainer>
              <ChatContainer className="p-3" >       
                <MessageList id="message_list" ref={msgListRef} scrollBehavior={scrollBehavior}
                  className = "custom-message-list"
                 
                  autoScrollToBottom = {true}
                  autoScrollToBottomOnMount = {true}
                  typingIndicator={isTyping ? <TypingIndicator content="PECA is typing" /> : null}  
                >
                  {messages.map((message, i) => { 
                    return <Message key={i} model={message} />
                  })}
                </MessageList>
                {showMessageInputDiv && <MessageInput className='MessageInput' placeholder="Send a Message" onSend={handleSendRequest} /> }
              </ChatContainer>
            </MainContainer>
            {/* { n == 4 && <div className='quotaMessage'>Your three questions quota has been completed.</div>}      */}
        </div><br/><br/>
        </div>
        <div className='col-md-4'>
        {showExtraQuestionDiv && <div className='container'>
            <h5>Click on the question, if you need the answer.</h5>
          <ol className="list-group">
            { listItemElements }
          </ol>
          </div>}
         
        </div>
      </div>
      
    </div>
  )
}

export default App;